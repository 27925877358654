
















.card {
  background-color: #fafafa;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;

  &__footer {
    padding: 12px 18px;
    border-top: 1px solid #e7e7e7;
  }
}
