




















































































































































.assigned {
  padding: 4px 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  border-radius: 4px 0px 0px 4px;
  background-color: #27ae60;
  color: #fff;
  position: absolute;
  top: 24px;
  right: 0;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  z-index: 1;
}

.media {
  padding: 16px 32px;
  overflow-y: auto;

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 25px;
    row-gap: 25px;
    margin-bottom: 16px;
  }

  &__image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    display: block;
    background-color: white;

    &-link {
      text-decoration: none;
      width: 100%;
      height: 100%;
      line-height: 180px;
      text-align: center;
      vertical-align: middle;
    }
  }

  .c-icon-image {
    font-size: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .c-icon-three-dots {
    font-size: 18px;
    cursor: pointer;
  }

  &__details {
    display: flex;
    align-items: center;
  }

  &__info {
    margin: 0 18px;
  }

  &__author {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }

  &__date {
    font-size: 10px;
    line-height: 15px;
    color: #999999;
  }
}

.dropdown {
  height: 14px;
}

.dropdown-item {
  display: flex;
  align-items: center;

  &__value {
    margin: 0 10px;
  }
}

.c-icon-eye {
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .media {
    &__list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 425px) {
  .media {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
